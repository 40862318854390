
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

:root {
  --color-primary: #eb5e7c;
  --color-primary-trans: #eb5e7c25;
  --color-primary-dark: #b3475e;
  --color-white: #fff;
  --color-white-trans: rgba(255, 255, 255, 0.95);
  --color-lightgrey: #ECEEF1;
  --color-mediumlightgrey: #cacdd1;
  --color-mediumgrey: #70747B;
  --color-darkgrey: #1F2227;
  --color-green: #36BC8C;
  --color-red: #ff5454;
}

body {
  background-color: var(--color-lightgrey);
  font-family: 'Play', sans-serif;
  color: var(--color-darkgrey);
  line-height: 130%;
  font-size: 15px;
}

#wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  max-width: 1024px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0 auto;
}
.extraPadding {
  padding: 0 20px;
}


.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white-trans);
}
.loader img {
  width: 64px;
  height: 64px;
}

.docsLoader {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.docsLoader img {
  width: 32px;
  height: 32px;
}


.switchWrapper {
  position: relative;
}
.switchWrapper > div {
  position: absolute;
  width: 100%;
}
.page {
  flex: 1;
}
.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
}
.primaryBold {
  color: var(--color-primary);
  font-weight: 700;
}
.normal {
  color: var(--color-darkgrey);
}
.light {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-mediumgrey);
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 18px;
  margin: 0 auto;
  max-width: 1024px;
  box-sizing: border-box;
}

#logo {
  width: 160px;
  height: auto;
}

#contentHeader {
  background-color: var(--color-primary);
  padding-top: 20px;
  padding-bottom: 70px;
  position: relative;
}
.headerTitle {
  color: var(--color-white);
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}
.headerSubtitle {
  color: var(--color-white);
  font-size: 16px;
}

#content {
  position: relative;
  margin-top: -50px;
}

label {
  color: var(--color-darkgrey);
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
}
label.big {
  font-size: 18px;
  margin-bottom: 15px;
}
a {
  color: var(--color-primary);
  text-decoration: none;
  -webkit-tap-highlight-color: var(--color-lightgrey);
}
a:active {
  background-color: var(--color-lightgrey);
}
p {
  margin: 0;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 33.333% 1 4;
  margin: 0 8px;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 5, 0.08);
  padding: 20px;
  box-sizing: border-box;
  max-width: calc(100vw - 36px);
}
.wideBox {
  flex: 66.666% 1 0;
  flex-basis: 65.333%;
  flex-direction: row;
}
.iconBox {
  min-height: 140px;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
}
.iconBox label {
  cursor: pointer;
}
.iconBox svg {
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-bottom: 15px;
}
.iconBox.wideBox {
  padding-left: 30px;
  padding-right: 30px;
}
.iconBox.wideBox svg {
  margin-bottom: 0;
  margin-right: 25px;
}

.iconBox.disabled {
  cursor: default;
  background-color: var(--color-lightgrey);
  border: 1px solid var(--color-mediumgrey);
}
.iconBox.disabled label {
  cursor: default;
  color: var(--color-mediumgrey);
}
.iconBox.disabled svg {
  filter: grayscale(1);
  opacity: .7;
}
.iconBox.disabled p {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
}

.uploadIcon {
  height: 36px !important;
  width: 36px !important;
}

.infoContainer {
  flex-shrink: 0;
  max-width: 200px;
}
.thumbnailContainer {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  padding-left: 30px;
  max-width: 60%;
}
.thumbnail {
  max-width: 100%;
  max-height: 235px;
}
.listThumbnailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  overflow: hidden;
  margin-right: 15px;
}
.listThumbnail {
  max-height: 65px;
  max-width: 65px;
}

.status {
  width: 65px;
  text-align: center;
  border: 1px solid;
  border-radius: 15px;
  font-weight: 700;
  padding: 0 5px;
  box-sizing: border-box;
  margin-bottom: 6px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
}
.status.active {
  color: var(--color-green);
}
.status.inactive {
  color: var(--color-red);
}

.backButton {
  margin-right: 15px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}


.listItem {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--color-lightgrey);
}
.listItem.folder {
  cursor: pointer;
}
.listItem.folder:active {
  background-color: var(--color-lightgrey);
}


.folderHeader {  
  padding: 15px 15px 10px;
  border-bottom: 1px solid var(--color-lightgrey);
}
.folderBack {
  padding: 9px 0;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 0 5px;
  border: 1px solid var(--color-mediumlightgrey);
  text-align: center;
  color: var(--color-mediumgrey);
  border-radius: 4px;
  cursor: pointer;
}
.folderBack:active {
  background-color: var(--color-lightgrey);
}
.folderPath {
  font-size: 14px;
  color: var(--color-mediumgrey);
}
.folderIcon {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}
.folderTitle {
  font-weight: 700;
  font-size: 16px;
  color: var(--color-darkgrey);
}
.folderFileCount {
  color: var(--color-primary);
  font-size: 14px;
}
.triangle {
  margin-left: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent var(--color-mediumlightgrey);
}


.fileTitle {
  font-size: 16px;
  color: var(--color-darkgrey);
}
.fileMeta {
  font-size: 14px;
  color: var(--color-mediumgrey);
}
.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary);
  border-radius: 6px;
  padding: 8px;
  margin: -5px 0 -5px auto;
  cursor: pointer;
}
.downloadButton svg {
  width: 18px;
  height: 18px;
}


.search {
  position: relative;
  flex-basis: 100%;
}
.searchInput {
  border: none;
  border-radius: 50px;
  background-color: var(--color-primary-dark);
  height: 45px;
  width: 100%;
  padding: 0 20px 0 40px;
  font-size: 14px;
  color: var(--color-white);
  outline: none;
}
::-webkit-input-placeholder {
  color: var(--color-primary);
}
.searchButton {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 14px;
  left: 14px;
}


.textOverflowBox {
  position: relative;
  width: calc(100% - 94px);
  overflow: hidden;
}
.textOverflowBox > p {
  white-space: nowrap;
}
.textOverflowBox:after {
  position: absolute;
  display: block;
  content: '';
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  background: var(--color-white);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.listItem.folder:active .textOverflowBox:after { 
  background: #ECEEF1;
  background: linear-gradient(90deg, rgba(236,238,241,0) 0%, rgba(236,238,241,1) 100%);
}

.phoneBig {
  font-size: 28px;
  color: var(--color-primary);
  font-weight: 700;
  margin: 30px 0;
}




.languageSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 44px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}
.languageSelected img {
  width: 24px;
  height: 24px;
}
.languageOptions {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 44px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 30%);
  padding: 5px 0;
}
.languageOptions img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}




.timeline {
  padding-top: 10px;
  margin-left: 25px;
  border-left: 5px solid var(--color-primary);
}
.timelineBoxContainer {
  padding: 15px 0;
}
.timelineDateContainer {
  display: flex;
  position: relative;
  padding-top: 15px;
}
.timelineDateContainer:before {
  display: block;
  content: '';
  width: 28px;
  height: 28px;
  margin-left: -18px;
  background-color: var(--color-white);
  border-radius: 100%;
  border: 2px solid var(--color-primary);
}
.timelineBoxContainer .box {
  margin-bottom: 10px;
}
.timelineDate {
  margin-left: 10px;
  margin-top: 6px;
  color: var(--color-darkgrey);
  font-size: 20px;
  font-weight: 700;
}
.timelineDate.today {
  color: var(--color-primary);
  font-size: 24px;
}
.timelineEventType {
  font-weight: 700;
  margin-bottom: 6px;
}


.maintenanceForm {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.maintenanceForm .row {
  margin-bottom: 20px;
}
.maintenanceForm button {
  background-color: var(--color-darkgrey);
  color: var(--color-white);
  padding: 15px 25px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  min-width: 120px;
}

label {
  width: 100%;
}
label span {
  display: block;
  margin-left: 15px;
  margin-bottom: 5px;
}

input:not([type="checkbox"], [type="date"]), textarea {
  width: 100%;
}
input, textarea {
  border-radius: 50px;
  border: 1px solid var(--color-lightgrey);
  background-color: var(--color-lightgrey);
  height: 45px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--color-darkgrey);
  outline: none;
}
input:focus, textarea:focus {  
  border-color: var(--color-primary);
}
textarea {
  min-height: 140px;
  font-family: 'Play', sans-serif;
  border-radius: 25px;
  padding: 15px 20px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
}
.checkboxContainer span {
   margin: 0 0 0 10px;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

.centeredTitle {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  line-height: 1.2; 
}



footer {
  justify-content: flex-end;
  padding-bottom: 25px;
}


.uploadBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0,0,0,.5);
  backdrop-filter: blur(20px);
  overflow-y: auto;
}
.uploadPopup {
  position: absolute;
  width: 100%;
  top: 9%;
  bottom: 9%;
}
@media (max-width: 800px){
  .uploadPopup {
    top: 4%;
    bottom: 4%;
  }
}
.uploadHeading {
  position: relative;
  z-index: 11;
  text-align: center;
  color: var(--color-white);
}
.uploadHeading.green {
  color: var(--color-green);
}
.uploadClose {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 0;
  margin: 10px auto 0;
  padding: 10px 0 50px;
  max-width: 200px;
}
.uploadClose img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.uploadClose span {
  color: var(--color-white);
  font-size: 20px;
}


/* Dropzone Overrides */
.dzu-dropzone {
  z-index: 10;
  background-color: var(--color-white);
  width: 100%;
  max-width: 1024px;
  min-height: 400px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  border-radius: 12px !important;
}
.dzu-dropzone.finished {
  min-height: none !important;
  max-height: 0 !important;
  border: 0 !important;
}
.dzu-inputLabel {
  color: var(--color-primary) !important;
}
.dzu-previewContainer {
  padding: 9px 30px !important;
}
.dzu-inputLabelWithFiles {
  background-color: var(--color-lightgrey) !important;
  color: var(--color-primary) !important;
  padding: 6px 18px !important;
  margin-left: 30px !important;
  width: auto !important;
}
.dzu-submitButton {
  padding: 20px 28px !important;
  font-size: 16px !important;
  background-color: var(--color-primary) !important;
}
.dzu-submitButton:disabled {
  background-color: var(--color-mediumgrey) !important;
}



@media (min-width: 920px){

  .respContainer {    
    display: flex;
    gap: 30px;
  }
  .respHalf {
    width: 50%;
  }

}


